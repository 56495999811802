import {Injectable } from '@angular/core';
import {HttpParams } from "@angular/common/http";
import {HttpService} from "../../../services/http.service";

const baseURL: string = 'production/';
const segment: string = 'production-receipts/';

@Injectable({
  providedIn: 'root'
})
export class ProductionReceiptsService {
  constructor(
      private HttpService: HttpService,
  ) {}

  get(params: HttpParams) {
    return this.HttpService.get(baseURL+'read/'+segment, params );
  }
  getUnsynchronised(params: HttpParams) {
    return this.HttpService.get(baseURL+'read/'+segment+'unsynchronised', params );
  }
  getCountUnsynchronised() {
    return this.HttpService.get(baseURL+'read/'+segment+'count/unsynchronised');
  }
  getReceiptTotals(params: HttpParams) {
    return this.HttpService.get(baseURL+'read/'+segment+'receipt-totals', params);
  }
  getInvoiceableProjects(language: string, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/invoiceable-projects/'+language, params );
  }
  getInvoiceableReceiptsCount(params: HttpParams) {
    return this.HttpService.get(baseURL+'read/invoiceable-receipts/count', params );
  }
  getInvoiceableReceipts(language: string, project_id: number, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/invoiceable-receipts/'+language+'/'+project_id.toString(), params );
  }
  create(data: any) {
    return this.HttpService.post(baseURL+'create', data);
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update', data);
  }
  synchronise(data: any) {
    return this.HttpService.post(baseURL+'update/'+segment+'synchronise', data);
  }
  remove(receipt_id: number) {
    return this.HttpService.get(baseURL+'delete/'+segment+receipt_id.toString(),null );
  }
}
